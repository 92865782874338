import React, { useState } from "react";
import "./Navbar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // Import the FaBars icon

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const closeNavbar = () => {
    setIsActive(false);
  };

  const codePresent = localStorage.getItem("googleCode");
  const navigate = useNavigate();
  const registerationComplete = localStorage.getItem("registeredThanima");
  const signOutFunction = () => {
    localStorage.removeItem("googleCode");
    localStorage.removeItem("registeredThanima");
    localStorage.removeItem("registerNumber");

    navigate("/");
  };

  return (
    <>
      <nav className="navbar">
        <div className="nav_container">
          <div className="toggle-btn" onClick={toggleNavbar}>
            <FaBars className="i" />
          </div>
          <div className={`nav-items ${isActive ? "active" : ""}`}>
            <ul>
              <li>
                <a style={{ color: "#fff" }} href="#home" onClick={closeNavbar}>
                  Home
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#fff" }}
                  href="#about"
                  onClick={closeNavbar}
                >
                  About
                </a>
              </li>
              {registerationComplete === "true" && codePresent && (
                <li>
                  <Link
                    style={{ color: "#fff" }}
                    to="/dashboard"
                    onClick={closeNavbar}
                  >
                    Dashboard
                  </Link>
                </li>
              )}
              <li>
                <a
                  style={{ color: "#fff" }}
                  href="#gallery"
                  onClick={closeNavbar}
                >
                  Gallery
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#fff" }}
                  href="#contact"
                  onClick={closeNavbar}
                >
                  Contact
                </a>
              </li>
              {registerationComplete === "true" && codePresent && (
                <li style={{ cursor: "pointer" }} onClick={signOutFunction}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "Marcellus SC",
                      color: "#fff",
                    }}
                  >
                    Sign Out
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

// import React, { useState } from "react";
// import "./Navbar.css";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import { FaBars } from "react-icons/fa"; // Import the FaBars icon

// const Navbar = () => {
//   const [isActive, setIsActive] = useState(false);

//   const toggleNavbar = () => {
//     setIsActive(!isActive);
//   };
//   const codePresent = localStorage.getItem("googleCode");
//   const navigate = useNavigate();
//   const registerationComplete = localStorage.getItem("registeredThanima");
//   const signOutFunction = () => {
//     localStorage.removeItem("googleCode");
//     localStorage.removeItem("registeredThanima");

//     navigate("/");
//   };
//   return (
//     <>
//       {/* <LoginButton />*/}
//       {/* <LogoutButton /> */}

//       <nav className="navbar">
//         <div className="nav_container">
//           {/* <div className="logo">
//             <img src="images/logo.jpeg" alt="Logo" />
//           </div> */}
//           <div className="toggle-btn" onClick={toggleNavbar}>
//             <FaBars className="i" />
//           </div>
//           <div className={`nav-items ${isActive ? "active" : ""}`}>
//             <ul>
//               <li>
//                 <a style={{ color: "#fff" }} href="#home">
//                   Home
//                 </a>
//               </li>
//               <li>
//                 <a style={{ color: "#fff" }} href="#about">
//                   About
//                 </a>
//               </li>
//               {registerationComplete && codePresent && (
//                 <li>
//                   <Link style={{ color: "#fff" }} to="/dashboard">
//                     Dashboard
//                   </Link>
//                 </li>
//               )}

//               <li>
//                 <a style={{ color: "#fff" }} href="#gallery">
//                   Gallery
//                 </a>
//               </li>
//               <li>
//                 <a style={{ color: "#fff" }} href="#contact">
//                   Contact
//                 </a>
//               </li>
//               {registerationComplete && codePresent && (
//                 <li style={{ cursor: "pointer" }} onClick={signOutFunction}>
//                   <p
//                     style={{
//                       fontWeight: "600",
//                       fontFamily: "Marcellus SC",
//                       color: "#fff",
//                     }}
//                   >
//                     Sign Out
//                   </p>
//                 </li>
//               )}
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Navbar;

// // import React from 'react'
// // import Search from './Search'
// // import '../styles/Navbar.css'

// // const Navbar = () => {
// //   return (
// //     <div className="nav-container">
// //         <div className="logo">
// //             <img src="/img/logo.png" alt="" />
// //         </div>
// //         <Search/>
// //         <div className="userImg">
// //           <img src="/img/user/user2.png" alt=""/>
// //         </div>
// //     </div>
// //   )
// // }

// // export default Navbar
