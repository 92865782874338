import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { FaPaperPlane } from "react-icons/fa";
import EventContext from "../EventsContext";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddTeam.css";

const AddTeam = () => {
  const { eventId } = useParams();
  const { events, registration, fetchEvents, name, registerNumber } =
    useContext(EventContext);
  const event = events.find((e) => parseInt(e.id) === parseInt(eventId));
  const authToken = localStorage.getItem("googleCode");
  const [isLoadingPay, setIsLoadingPay] = useState(false);
  const navigate = useNavigate();

  const registered = registration.filter(
    (item) => parseInt(item.event) == eventId
  );
  // console.log(registered[0]?.team?.name);
  const [teamRequests, setTeamRequests] = useState([]);
  const [teamMembers, setTeamMembers] = useState({
    reg_no: "",
    event_id: eventId, // Assuming eventId is defined
  });

  const notify = (message) => {
    toast(message);
  };
  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/events/team/invites/?event=${eventId}`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.message);

        console.log(response.data.data.requests);
        setTeamRequests(response.data.data.requests);
      }
    } catch (error) {
      console.error("GET request error:", error);
      const { status, data } = error.response;

      if (status >= 400) {
        toast.error(data.message);
      } else {
        console.error("Unexpected server error:", status, data);
      }
      if (status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (error?.response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchRequests();
    // console.log(events);
    // console.log(registration);
  }, []);

  const handleSendInvite = (e) => {
    const { name, value } = e.target;
    setTeamMembers({
      ...teamMembers,
      [name]: value,
    });
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    setIsLoadingPay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/events/team/invite/`,
        JSON.stringify(teamMembers),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoadingPay(false);
        // notify(response.data.message);
        toast.success(response.data.message);
        //      console.log(response);
        // setTeamRequests(response.data.data.requests);
        fetchRequests();
        setTeamMembers({
          reg_no: "",
          event_id: eventId, // Assuming eventId is defined
        });
      }
    } catch (error) {
      // console.error("POST request error:", error);
      const { status, data } = error.response;
      setIsLoadingPay(false);
      if (status >= 400) {
        toast.error(data.message);
      } else {
        console.error("Unexpected server error:", status, data);
      }
      if (status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (error?.response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error?.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  return (
    <div className="addteam-container">
      <ToastContainer theme="dark" />
      <h1>Add Team</h1>
      <h3 style={{ color: "#fff", marginBottom: "10px" }}>
        Team Name:
        <span style={{ fontWeight: "600" }}>{registered[0]?.team?.name} </span>
      </h3>

      <p style={{ color: "#fff", marginBottom: "20px" }}>
        <span>Team Leader: </span>
        {name}
      </p>

      {event != null && event.type === "Team" && (
        <form className="invite_form">
          <div className="input-group invites">
            <div className="label-group">
              <label className={`label mandatory light`} htmlFor={`reg_no`}>
                Resgistration Number
                <span>*</span>
              </label>
            </div>
            <input
              name="reg_no"
              placeholder="Enter registration number."
              className="inp-field"
              type="text"
              id={`reg_no`}
              required
              value={teamMembers?.reg_no || ""}
              onChange={(e) => handleSendInvite(e)}
            />
          </div>
          <button
            type="submit"
            className="button"
            onClick={(e) => handleInvite(e)}
          >
            <FaPaperPlane className="i" /> Send Invite
            {isLoadingPay && (
              <>
                <div className="simple-spinner">
                  <span></span>
                </div>
              </>
            )}
          </button>
        </form>
      )}
      <div className="teamlist">
        {teamRequests?.map((item) => (
          <div key={item.id} className="invite_card">
            <div className="element">
              <p>
                <span>Name</span> - {item.participant.full_name}
              </p>
              <p>
                <span>Reg Number</span> - {item.participant.reg_no}
              </p>
            </div>
            <div className="element">
              <p
                className={`status button ${
                  item.status === "Rejected" ? "decline" : " "
                } ${item.status === "Accepted" ? "accepted" : " "} ${
                  item.status === "Pending" ? "pending" : " "
                }`}
              >
                {item.status}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddTeam;
