import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Invitations.css";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

const Invitations = () => {
  const authToken = localStorage.getItem("googleCode");
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/dashboard/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      // console.log(response.data);
      setRequests(response.data.data.requests);
      //     console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);

      setIsLoading(false);
      if (error?.response?.status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error?.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  function formatDateTime(timestamp) {
    if (!timestamp) {
      return { formattedDate: "", formattedTime: "" };
    }

    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      return { formattedDate: "", formattedTime: "" };
    }

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const dayStr =
      day +
      (day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th");
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    const formattedDate = `${dayStr} ${month} ${year}`;

    return { formattedDate, formattedTime };
  }
  var pendingInvites = requests?.filter((item) => item.status === "Pending");

  const handleAccept = async (e, id) => {
    e.preventDefault();
    try {
      const jsonPayload = JSON.stringify({
        accepted: "true",
        request_id: id,
      });
      //      console.log(jsonPayload);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/events/team/invite/respond/`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        }
      );
      //    console.log(response.data);
      setRequests(response.data.data.requests);
      //  console.log(response);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const handleDecline = async (e, id) => {
    e.preventDefault();
    try {
      const jsonPayload = JSON.stringify({
        accepted: "false",
        request_id: id,
      });
      // console.log(jsonPayload);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/events/team/invite/respond/`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        }
      );
      // console.log(response.data);
      setRequests(response.data.data.requests);
      // console.log(response);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  // useEffect(() => {
  //   //console.log("Updated requests:", requests);
  // }, [requests]);

  if (isLoading) {
    return (
      <div className="loader">
        <ReactLoading
          className="loading-anim"
          type="bubbles"
          color="#FFDE37"
          height={100}
          width={50}
        />
      </div>
    );
  }

  return (
    <div className="invitation-container">
      <h1>Invitations</h1>

      <div className="invitation-wrapper">
        <div className="invitation-display">
          {pendingInvites === null && <div>No Invitations Found</div>}
          {pendingInvites?.length > 0 ? (
            pendingInvites?.map((event) => (
              <div className="invite_card" key={event.id}>
                <h3>{event.event.name}</h3>
                <p className="detail">Team Name - {event.team.name}</p>

                <p className="detail">
                  Team Leader - {event.team.leader.full_name}
                </p>

                <p className="detail">{event.event.location}</p>

                <div className="date_n_time">
                  <p className="detail">
                    {formatDateTime(event.event.date).formattedDate}
                  </p>
                  |
                  <p className="detail">
                    {formatDateTime(event.event.date).formattedTime}
                  </p>
                </div>
                <div className="buttons_element">
                  <button
                    type="submit"
                    className="btn"
                    onClick={(e) => handleAccept(e, event.id)}
                  >
                    Accept
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    onClick={(e) => handleDecline(e, event.id)}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="null_msg">No Invitations Found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invitations;
