// EventContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [events, setEvents] = useState([]);
  const [registerNumber, setRegisterNumber] = useState("");
  const [name, setName] = useState("");
  const [registration, setRegisteration] = useState([]);
  const navigate = useNavigate();

  const fetchEvents = async () => {
    const authToken = localStorage.getItem("googleCode");
    if (!authToken) {
      console.log("Fetch events called but authToken not set");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/dashboard/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (response.status === 403) {
        navigate("/");
      } else if (response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
      setEvents(response.data.data.events);
      setRegisterNumber(response.data.data.regNo);
      setName(response.data.data.name);
      setRegisteration(response.data.data.registrations);
      setEventsLoaded(true);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error?.response?.status === 403) {
        navigate("/");
      } else if (error?.response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error?.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  return (
    <EventContext.Provider
      value={{ events, fetchEvents, name, registerNumber, registration }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
// setEvents([
//   {
//     id: 2,
//     name: "DigiPookalam",
//     subname: "Poster",
//     description: null,
//     rules:
//       "<strong>Rules and Regulations:</strong> <ul> <li>Participants will have to submit a short landscape video under 3 min so as to be shared on our official instagram and youtube pages.</li> <li>The size of the video must be 1080px width and 1920px height.</li> <li>The video must be uploaded in MP4 format in your personal google drive and must paste the link for that in our submission portal for evaluation.</li> <li>For this you are to make a video in which a group performance is mandatory. You are free to make dance videos, songs, skits, storytelling etc as long as it is relevant to the theme of “ONAM”.</li> <li>The judges’ decision is final and binding.</li> <li>It is mandatory to attend the Thanima event(25th September 2022) to claim your prize.</li> <li>If any hateful or harmful content is found on the video, participant will be disqualified.</li> </ul>",
//     judging_criteria:
//       "<strong>Judging Criteria:</strong> <ul> <li>Originality – 10% <ul> <li>It must show the ability to be inventive and creative.</li></ul> </li> <li>Shares and Likes – 15% <ul><li>The score will depend on the number of views and likes on the final video shared on our instagram and youtube pages. </li></ul> </li> <li>Relevance to the theme – 25% <ul><li>The design should effectively communicate from the given theme.</li></ul> </li> <li>Group Contribution - 30% <ul><li>The contribution of all members who partook in the making of the said video is taken into consideration and marks will be awarded for their quality of work, dedication, responsibilities executed and innovative ideas pitched during production. </li></ul> </li> <li>Aesthetic Appeal – 20% <ul><li>The video must captivate the judges’ senses. The video must be neatly edited and have a professional touch fitting the standard of our institution.</li></ul> </li> </ul>",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Individual",
//     team_min: 1,
//     team_max: 1,
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-09-30T00:00:00Z",
//     file_submission: false,
//     link_submission: true,
//     category: "Pre-Thanima",
//     accepted_formats: ["PNG", "JPEG", "JPG"],
//   },
//   {
//     id: 3,
//     name: "Volleyball",
//     subname: "Game",
//     description: null,
//     rules:
//       "<strong>Rules and Regulations:</strong> <ul> <li>Participants will have to submit a short landscape video under 3 min so as to be shared on our official instagram and youtube pages.</li> <li>The size of the video must be 1080px width and 1920px height.</li> <li>The video must be uploaded in MP4 format in your personal google drive and must paste the link for that in our submission portal for evaluation.</li> <li>For this you are to make a video in which a group performance is mandatory. You are free to make dance videos, songs, skits, storytelling etc as long as it is relevant to the theme of “ONAM”.</li> <li>The judges’ decision is final and binding.</li> <li>It is mandatory to attend the Thanima event(25th September 2022) to claim your prize.</li> <li>If any hateful or harmful content is found on the video, participant will be disqualified.</li> </ul>",
//     judging_criteria:
//       "<strong>Judging Criteria:</strong> <ul> <li>Originality – 10% <ul> <li>It must show the ability to be inventive and creative.</li></ul> </li> <li>Shares and Likes – 15% <ul><li>The score will depend on the number of views and likes on the final video shared on our instagram and youtube pages. </li></ul> </li> <li>Relevance to the theme – 25% <ul><li>The design should effectively communicate from the given theme.</li></ul> </li> <li>Group Contribution - 30% <ul><li>The contribution of all members who partook in the making of the said video is taken into consideration and marks will be awarded for their quality of work, dedication, responsibilities executed and innovative ideas pitched during production. </li></ul> </li> <li>Aesthetic Appeal – 20% <ul><li>The video must captivate the judges’ senses. The video must be neatly edited and have a professional touch fitting the standard of our institution.</li></ul> </li> </ul>",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     team_min: 8,
//     team_max: 10,
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-09-30T00:00:00Z",
//     file_submission: false,
//     link_submission: false,
//     category: "Sports",
//     accepted_formats: [],
//   },
//   {
//     id: 1,
//     name: "Lemon Spoon",
//     subname: "Race",
//     description: null,
//     rules:
//       "<strong>Rules and Regulations:</strong> <ul> <li>Participants will have to submit a short landscape video under 3 min so as to be shared on our official instagram and youtube pages.</li> <li>The size of the video must be 1080px width and 1920px height.</li> <li>The video must be uploaded in MP4 format in your personal google drive and must paste the link for that in our submission portal for evaluation.</li> <li>For this you are to make a video in which a group performance is mandatory. You are free to make dance videos, songs, skits, storytelling etc as long as it is relevant to the theme of “ONAM”.</li> <li>The judges’ decision is final and binding.</li> <li>It is mandatory to attend the Thanima event(25th September 2022) to claim your prize.</li> <li>If any hateful or harmful content is found on the video, participant will be disqualified.</li> </ul>",
//     judging_criteria:
//       "<strong>Judging Criteria:</strong> <ul> <li>Originality – 10% <ul> <li>It must show the ability to be inventive and creative.</li></ul> </li> <li>Shares and Likes – 15% <ul><li>The score will depend on the number of views and likes on the final video shared on our instagram and youtube pages. </li></ul> </li> <li>Relevance to the theme – 25% <ul><li>The design should effectively communicate from the given theme.</li></ul> </li> <li>Group Contribution - 30% <ul><li>The contribution of all members who partook in the making of the said video is taken into consideration and marks will be awarded for their quality of work, dedication, responsibilities executed and innovative ideas pitched during production. </li></ul> </li> <li>Aesthetic Appeal – 20% <ul><li>The video must captivate the judges’ senses. The video must be neatly edited and have a professional touch fitting the standard of our institution.</li></ul> </li> </ul>",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Individual",
//     team_min: 1,
//     team_max: 1,
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-09-30T00:00:00Z",
//     file_submission: false,
//     link_submission: false,
//     category: "Pre-Thanima",
//     accepted_formats: [],
//   },
//   {
//     id: 4,
//     name: "DigiPookalam",
//     subname: "Poster",
//     description: null,
//     rules:
//       "<strong>Rules and Regulations:</strong> <ul> <li>Participants will have to submit a short landscape video under 3 min so as to be shared on our official instagram and youtube pages.</li> <li>The size of the video must be 1080px width and 1920px height.</li> <li>The video must be uploaded in MP4 format in your personal google drive and must paste the link for that in our submission portal for evaluation.</li> <li>For this you are to make a video in which a group performance is mandatory. You are free to make dance videos, songs, skits, storytelling etc as long as it is relevant to the theme of “ONAM”.</li> <li>The judges’ decision is final and binding.</li> <li>It is mandatory to attend the Thanima event(25th September 2022) to claim your prize.</li> <li>If any hateful or harmful content is found on the video, participant will be disqualified.</li> </ul>",
//     judging_criteria:
//       "<strong>Judging Criteria:</strong> <ul> <li>Originality – 10% <ul> <li>It must show the ability to be inventive and creative.</li></ul> </li> <li>Shares and Likes – 15% <ul><li>The score will depend on the number of views and likes on the final video shared on our instagram and youtube pages. </li></ul> </li> <li>Relevance to the theme – 25% <ul><li>The design should effectively communicate from the given theme.</li></ul> </li> <li>Group Contribution - 30% <ul><li>The contribution of all members who partook in the making of the said video is taken into consideration and marks will be awarded for their quality of work, dedication, responsibilities executed and innovative ideas pitched during production. </li></ul> </li> <li>Aesthetic Appeal – 20% <ul><li>The video must captivate the judges’ senses. The video must be neatly edited and have a professional touch fitting the standard of our institution.</li></ul> </li> </ul>",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Individual",
//     team_min: 1,
//     team_max: 1,
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-09-30T00:00:00Z",
//     file_submission: false,
//     link_submission: true,
//     category: "Digital Event",
//     accepted_formats: ["PNG", "JPEG", "JPG"],
//   },
// ]);

// setEvents([
//   {
//     eventId: 1,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Vadamvali",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     type: "Group",
//     team_size: 8,
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 2,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Event 1",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     team_size: 12,
//     type: "Individual",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 3,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Event 1",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     type: "Group",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 4,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Event 1",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     type: "Individual",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 5,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Event 1",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     type: "Individual",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 6,
//     imageUrl: "images/kathakali.jpeg",
//     category: "Pre Thanima",
//     date: "2023-12-03",
//     time: "10 am",
//     venue: "Anna Audi",
//     name: "Event 1",
//     subname: "Subtitle 1",
//     description: "Event 1 Description",
//     rules: "Event 1 Rules",
//     judging_criteria: "Event 1 Criteria",
//     prize_reveal: true,
//     prize_1: 1000,
//     prize_2: 500,
//     prize_3: 250,
//     type: "Individual",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-31T23:59:59Z",
//     file_submission: true,
//     accepted_formats: ["TIFF", "JPEG"],
//   },
//   {
//     eventId: 7,
//     name: "Event 2",
//     subname: "Subtitle 2",
//     imageUrl: "images/pic2.jpg",
//     category: "Sports",
//     venue: "Anna Audi",
//     date: "2023-12-03",
//     time: "10 am",
//     description:
//       "MUJ ACM, the recipient of the prestigious title solve robotic challenges and build creative Bots,     while learning, science, technology, engineering, and maths. This also comes with      a prize pool of INR 35,000 and will be hosted on the campus of Manipal University.",
//     rules: "Event 2 Rules",
//     judging_criteria: "Event 2 Criteria",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-15T23:59:59Z",
//     file_submission: false,
//     accepted_formats: [],
//   },
//   {
//     eventId: 8,
//     name: "Event 2",
//     subname: "Subtitle 2",
//     imageUrl: "images/pic2.jpg",
//     category: "Sports",
//     venue: "Anna Audi",
//     date: "2023-12-03",
//     time: "10 am",
//     description: "Event 2 Description",
//     rules: "Event 2 Rules",
//     judging_criteria: "Event 2 Criteria",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-15T23:59:59Z",
//     file_submission: false,
//     accepted_formats: [],
//   },
//   {
//     eventId: 9,
//     name: "Event 2",
//     subname: "Subtitle 2",
//     imageUrl: "images/pic.png",
//     category: "Sports",
//     venue: "Anna Audi",
//     date: "2023-12-03",
//     time: "10 am",
//     description: "Event 2 Description",
//     rules: "Event 2 Rules",
//     judging_criteria: "Event 2 Criteria",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-15T23:59:59Z",
//     file_submission: false,
//     accepted_formats: [],
//   },
//   {
//     eventId: 10,
//     name: "Event 2",
//     subname: "Subtitle 2",
//     imageUrl: "images/pic2.jpg",
//     category: "Digital",
//     venue: "Anna Audi",
//     date: "2023-12-03",
//     time: "10 am",
//     description: "Event 2 Description",
//     rules: "Event 2 Rules",
//     judging_criteria: "Event 2 Criteria",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-15T23:59:59Z",
//     file_submission: false,
//     accepted_formats: [],
//   },
//   {
//     eventId: 11,
//     name: "Event 2",
//     subname: "Subtitle 2",
//     imageUrl: "images/img.png",
//     category: "Digital",
//     venue: "Anna Audi",
//     date: "2023-12-03",
//     time: "10 am",
//     description: "Event 2 Description",
//     rules: "Event 2 Rules",
//     judging_criteria: "Event 2 Criteria",
//     prize_reveal: false,
//     prize_1: null,
//     prize_2: null,
//     prize_3: null,
//     type: "Team",
//     eligible_genders: ["Male", "Female"],
//     deadline: "2023-12-15T23:59:59Z",
//     file_submission: false,
//     accepted_formats: [],
//   },
// ]);
