import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authCode = new URLSearchParams(location.search).get("code");

  const [isLoading, setIsLoading] = useState(true);
  const [responseMsg, setResponseMessage] = useState("");

  useEffect(() => {
    if (authCode) {
      const data = {
        code: authCode,
      };

      axios
        .post(`${process.env.REACT_APP_BACKEND_URI}/api/auth/google/`, data)
        .then((response) => {
          //   console.log("Response from backend:", response.data);
          if (response.data.key) {
            // Store the response data in localStorage
            localStorage.setItem("googleCode", response.data.key);
            localStorage.setItem(
              "registeredThanima",
              response.data.reg_complete
            );
            localStorage.setItem("registerNumber", response.data.reg_no);

            if (localStorage.getItem("registeredThanima") === "false") {
              navigate("/form");
            } else if (localStorage.getItem("registeredThanima") === "true") {
              navigate("/dashboard");
            } else {
              console.error("Empty field");
            }
          } else {
            console.error("Invalid response from the server");
          }
        })
        .catch((error) => {
          console.error("Error sending auth code to backend:", error);
          if (error.response.status === 422) {
            navigate(
              `/registrationfailed?message=${error.response.data.message}`
            );
          }
        });
      // .finally(() => {
      // Clear the 'code' parameter from the URL to prevent reuse
      // const urlWithoutCode =
      //   window.location.origin + window.location.pathname;
      // window.history.replaceState({}, document.title, urlWithoutCode);
      // });
    } else {
      // Handle the case where authCode is missing
      console.error("Auth code is missing");
    }
  }, [authCode, navigate]);

  return (
    <div>
      <div className="loader">
        <ReactLoading
          className="loading-anim"
          type="bubbles"
          color="#FFDE37"
          height={100}
          width={50}
        />
      </div>
    </div>
  );
};

export default GoogleCallback;
