import React, { useState } from "react";
import "./FormInputField.css";

const FormInputField = ({
  title,
  desc,
  type,
  mandatory,
  placeholder,
  value,
}) => {
  if (type === "disabled") {
    return (
      <div className="input-group">
        <div className="label-group">
          <label
            className={`label ${mandatory ? "mandatory" : ""} light`}
            name={title}
          >
            {title}
            <span>*</span>
          </label>
          <p className="inp-desc">{desc}</p>
        </div>
        <input
          placeholder={placeholder}
          className="inp-field"
          type="text"
          id={title}
          value={value}
          disabled
        />
      </div>
    );
  } else {
    return (
      <div className="input-group">
        <div className="label-group">
          <label
            className={`label ${mandatory ? "mandatory" : ""} light`}
            name={title}
          >
            {title}
            <span>*</span>
          </label>
          <p className="inp-desc">{desc}</p>
        </div>
        <input
          placeholder={placeholder}
          className="inp-field"
          type="text"
          id={title}
          value={value}
        />
      </div>
    );
  }
};

export default FormInputField;
// const InputForm = ({ title, mandatory, desc, type }) => {
//   const theme = "light";
//   const [addressField, setAddressField] = useState([{ addressLine: "" }]);
//   const addFields = (e) => {
//     e.preventDefault();

//     let newfield = {
//       addressLine: "",
//     };
//     setAddressField([...addressField, newfield]);
//   };
//   if (type === "long") {
//     return (
//       <div className="input-group">
//         <div className="label-group">
//           <label
//             className={`label ${mandatory ? " mandatory" : ""} ${
//               theme === "light" ? "light" : "dark"
//             }`}
//             name={title}
//           >
//             {title}
//             <span>*</span>
//           </label>
//           <p className="inp-desc">{desc}</p>
//         </div>
//         <input className="inp-field" type="text" id={title} />
//       </div>
//     );
//   }
//   if (type === "short")
//     return (
//       <div className="input-group short">
//         <label
//           className={`label ${mandatory ? " mandatory" : ""} ${
//             theme === "light" ? "light" : "dark"
//           }`}
//           name={title}
//         >
//           {title}
//           <span>*</span>
//         </label>
//         <input
//           className="inp-field"
//           type="text"
//           id={title}
//           placeholder={desc}
//         />
//       </div>
//     );
//   if (type === "dynamic-inp")
//     return (
//       <div className="input-group dynamic-inp">
//         <div className="label-group">
//           <label
//             className={`label ${mandatory ? " mandatory" : ""} ${
//               theme === "light" ? "light" : "dark"
//             }`}
//             name={title}
//           >
//             {title}
//             <span>*</span>
//           </label>
//         </div>
//         <div className="dynamic-input-group">
//           {addressField.map((input, index) => {
//             return (
//               <input
//                 key={index}
//                 className="inp-field"
//                 type="text"
//                 id={title}
//                 value={input.address}
//                 placeholder={desc}
//               />
//             );
//           })}
//           <button class="btn-blank" onClick={addFields}>
//             + Add Address Line
//           </button>
//         </div>
//       </div>
//     );
//   if (type === "popup")
//     return (
//       <div className="input-group popup-inp">
//         <label
//           className={`label ${mandatory ? " mandatory" : ""} ${
//             theme === "light" ? "light" : "dark"
//           }`}
//           name={title}
//         >
//           {title}
//           <span>*</span>
//         </label>
//         <input
//           className="inp-field"
//           type="text"
//           id={title}
//           placeholder={desc}
//         />
//       </div>
//     );

//   if (type === "text-box")
//     return (
//       <div className="input-group text-box">
//         <label
//           className={`label ${mandatory === true && " mandatory"} ${
//             theme === "light" ? "light" : "dark"
//           }`}
//           name={title}
//         >
//           {title}
//           <span>*</span>
//         </label>
//         <textarea
//           className="text-field"
//           type="text"
//           id={title}
//           placeholder={desc}
//         />
//       </div>
//     );
//   if (type === "option")
//     return (
//       <div className=" option input-group">
//         <label
//           className={`label ${mandatory === true && " mandatory"} ${
//             theme === "light" ? "light" : "dark"
//           }`}
//           name={title}
//         >
//           {title}
//           <span>*</span>
//         </label>
//         <input className="text-field" type="checkbox" id={title} />
//       </div>
//     );
//   if (type === "select")
//     return (
//       <div>
//         <div className="input-group short">
//           <label
//             className={`label ${mandatory ? " mandatory" : ""} ${
//               theme === "light" ? "light" : "dark"
//             }`}
//             name={title}
//           >
//             {title}
//             <span>*</span>
//           </label>
//           {/* dropdown */}
//         </div>
//       </div>
//     );
// };

// export default InputForm;
