import React, { useContext, useEffect, useState } from "react";
import FormInputField from "../FormInputField/FormInputField";
import { useNavigate, useParams } from "react-router-dom";
import EventContext from "../EventsContext";
import "./Register.css";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPaperPlane } from "react-icons/fa";

const Register = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { events, registerNumber, eventsLoaded, fetchEvents } =
    useContext(EventContext);
  const event = events.find((e) => parseInt(e.id) == eventId);
  const authToken = localStorage.getItem("googleCode");
  const [fileUploaded, setFileUploaded] = useState(false); // State variable to track file upload
  const [selectedFileName, setSelectedFileName] = useState(""); // State to store the selected file name
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const [formData, setFormData] = useState({
    team_name: "",
    event_id: event?.id,
  });
  const [formData2, setFormData2] = useState({
    team_name: "",
    event_id: event?.id,
    file: "",
  });
  useEffect(() => {
    if (!eventsLoaded) {
      fetchEvents();
    }
    setFormData({
      ...formData,
      event_id: event?.id,
    });
  }, [event]);

  const notify = (message) => {
    toast(message);
  };

  if (!event) {
    console.error(`Event with ${eventId} not found`);
    return <div>Event not found</div>;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    //    console.log(name + " " + value);
  };

  const handleSubmit = async (e, flag) => {
    e.preventDefault();
    console.log(formData);
    if (!flag) {
    }
    try {
      setIsLoadingPay(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/events/register/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${authToken}`,
          },
        }
      );
      //    console.log("POST request success:", response.data);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        // notify(response.data.message);
        if (event.type === "Team") {
          window.location.replace(`/dashboard/event/addteam/${eventId}`);
        } else {
          window.location.replace(`/dashboard`);
        }
      }
    } catch (error) {
      setIsLoadingPay(false);
      // Handle errors, e.g., show error message
      console.error("POST request error:", error);
      if (error.request) {
        console.error("No response from server", error.request);
      }
      console.error("POST request error:", error);
      const { status, data } = error?.response;

      if (status >= 400) {
        // console.log(status + " " + data.data.message);
        toast.error(data?.data?.message);
      } else {
        console.error("Unexpected server error:", status, data);
      }
    }
  };

  const handleChooseFileClick = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileID");
    fileInput.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file: file,
    });
    setSelectedFileName(file.name);
    setFileUploaded(true);
  };
  const handleLinkChange = (e) => {
    const value = e.target.value;
    setFormData2({
      ...formData2,
      file: value,
    });
  };

  // const teamSize = event.team_max;
  const formattedFormats = event.accepted_formats.map(
    (format) => `.${format.toLowerCase()}`
  );

  const result = formattedFormats.join(", ");

  return (
    <div className="event_register_container">
      <ToastContainer />
      <h1>Registration</h1>
      <ToastContainer theme="dark" />

      <form
        className="register_form"
        onSubmit={(e) => handleSubmit(e, event.file_submission)}
      >
        <div className="register_details">
          <p>Event Name</p>
          <h2 style={{ color: "#000" }}>{event.name}</h2>
          <div className="other_details">
            {event.team_max === 1 ? (
              <p>
                <span>Participation - </span>
                Individual
              </p>
            ) : (
              <p>
                <span>Team Size -</span>
                {event.team_min !== event.team_max
                  ? `${event.team_min} - ${event.team_max}  members`
                  : `${event.team_min}  members`}
              </p>
            )}

            <p>
              <span>Eligibility - </span> {event.eligible_genders.join(", ")}
            </p>
          </div>
        </div>
        <div className="form-group">
          {event.type === "Team" && (
            <div className="input-group">
              <div className="label-group">
                <label className={`label mandatory light`} htmlFor="full_name">
                  Team Name
                  <span>*</span>
                </label>
              </div>
              <input
                placeholder="Enter your team name."
                className="inp-field"
                type="text"
                id="team_name"
                name="team_name"
                required
                value={formData.team_name}
                onChange={handleInputChange}
              />
            </div>
          )}

          <FormInputField
            title={event.type === "Team" ? "Team Leader" : "Register Number"}
            value={registerNumber}
            type="disabled"
          />
        </div>

        {event.file_submission && (
          <>
            <div className="drop_box">
              <header>
                <h4>Select File here</h4>
              </header>
              <p>
                Files Supported:{" "}
                {event.accepted_formats.join(", ").toLowerCase()}
              </p>
              <input
                type="file"
                hidden
                accept="*"
                id="fileID"
                style={{ display: "none" }}
                alt="file_accept"
                onChange={handleFileChange} // Add an onChange handler to capture the selected file
              />
              <button className="btn" onClick={handleChooseFileClick}>
                Choose File
              </button>
            </div>
            {fileUploaded && (
              <div className="show_file" style={{ alignSelf: "center" }}>
                {selectedFileName}
              </div>
            )}{" "}
          </>
        )}
        {event.link_submission && (
          <div className="input-group">
            <div className="label-group">
              <label className={`label mandatory light`} htmlFor="file">
                Enter Link
                <span>*</span>
              </label>
            </div>
            <input
              placeholder="Enter your link submission."
              className="inp-field"
              type="text"
              id="file"
              name="file"
              required
              value={formData.file}
              onChange={handleInputChange}
            />
          </div>
        )}
        <button type="submit" className="button" disabled={isLoadingPay}>
          Submit
          {isLoadingPay && (
            <>
              <div class="simple-spinner">
                <span></span>
              </div>
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default Register;
// {/* {event.type === "Team" && (
//   <button
//     type="button"
//     className="button add_button"
//     onClick={() => navigate(`/dahsboard/event/addteam/${eventId}`)}
//   >
//     <span>+</span> Add Team Mates
//   </button>
// )} */}
// {/* {event.type === "Team" && teamMemberFields} */}
// const [teamMembers, setTeamMembers] = useState([]);

// const handleSendInvite = (e, index) => {
//   const { name, value } = e.target;
//   const updatedTeamMembers = [...teamMembers];
//   if (!updatedTeamMembers[index]) {
//     updatedTeamMembers[index] = {}; // Initialize the object if it doesn't exist
//   }
//   updatedTeamMembers[index] = {
//     ...updatedTeamMembers[index],
//     reg_no: value,
//     event_id: event.id, // Replace with the actual event_id
//   };
//   setTeamMembers(updatedTeamMembers);
// };

// const handleInvite = async (e, index) => {
//   e.preventDefault();
//   console.log(teamMembers[index]);
//   try {
//     const jsonPayload = JSON.stringify(teamMembers[index]);
//     const response = await axios.post(
//       `{BASE}/api/events/team/invite/`,
//       jsonPayload,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Token ${authToken}`,
//         },
//       }
//     );
//     console.log("POST request success:", response.data);
//   } catch (error) {
//     console.error("POST request error:", error);
//     const { status, data } = error.response;

//     if (status === 422) {
//       if (data.detail === "Team already created for this event by you.") {
//         setError("Team already created for this event by you.");
//       } else if (
//         data.detail ===
//         "You are already part of a complete team for this event."
//       ) {
//         setError("You are already part of a complete team for this event.");
//       } else if (data.detail === "Team name already taken for the event.") {
//         setError("Team name already taken for the event.");
//       }
//     } else {
//       console.error("Unexpected server error:", status, data);
//     }
//   }
// };

// const teamMemberFields = [];

// for (let i = 2; i <= teamSize; i++) {
//   teamMemberFields.push(
//     <form className="invite_form" key={i}>
//       <div className="input-group invites">
//         <div className="label-group">
//           <label className={`label mandatory light`} htmlFor={`reg_no_${i}`}>
//             Team Member - {i}
//             <span>*</span>
//           </label>
//         </div>
//         <input
//           name="reg_no"
//           placeholder="Enter registration number."
//           className="inp-field"
//           type="text"
//           id={`reg_no_${i}`}
//           value={teamMembers[i - 2]?.reg_no || ""}
//           onChange={(e) => handleSendInvite(e, i - 2)}
//         />
//       </div>
//       <button
//         type="submit"
//         className="button"
//         onClick={(e) => handleInvite(e, i - 2)}
//       >
//         <FaPaperPlane className="i" /> Send Invite
//       </button>
//     </form>
//   );
// }
