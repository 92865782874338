import { Link } from "react-router-dom";
import "./Gallery.css";

const Gallery = () => {
  return (
    <>
      <div
      // style={{
      //   overflowX: "auto",
      // }}
      >
        <table id="gallery" className="gallery">
          <tr>
            <td>
              <img src="images/t1.jpeg" alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t2.jpeg" alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t3.jpeg" alt="pic" />{" "}
            </td>
          </tr>
          <tr>
            <td>
              <img src="images/t4.jpg " alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t5.jpeg" alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t6.jpeg" alt="pic" />{" "}
            </td>
          </tr>
          <tr>
            <td>
              <img src="images/t7.jpeg" alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t8.jpeg" alt="pic" />{" "}
            </td>
            <td>
              <img src="images/t9.jpeg" alt="pic" />{" "}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default Gallery;
