import { useEffect, useState, useContext } from "react";
import EventCard from "../EventCard/EventCard";
import EventContext from "../EventsContext";
import "./Dashboard.css";
import axios from "axios";
import ReactLoading from "react-loading";
import { Link, json, useNavigate } from "react-router-dom";
import {
  FaCalendarAlt,
  FaList,
  FaMapMarkerAlt,
  FaMoneyBillAlt,
  FaRegUserCircle,
  FaTimes,
  FaUserAlt,
  FaUserCircle,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const { fetchEvents } = useContext(EventContext);
  const [events, setEvents] = useState([]);
  const [registerNumber, setRegisterNumber] = useState("");
  const [registrations, setRegistrations] = useState("");
  const [payed, setPayed] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const authToken = localStorage.getItem("googleCode");
  const registrationComplete = localStorage.getItem("registeredThanima");

  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const fetchQR = async () => {
    try {
      setIsLoadingPay(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/payments/start/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        try {
          let formData = new FormData();
          formData.append("registrationNo", response.data.data.referenceNo);
          formData.append("name", response.data.data.name);
          formData.append("email", response.data.data.email);
          formData.append("mobileNo", response.data.data.mobileNo);
          formData.append("amount", response.data.data.amount);
          //    console.log(response.data.data.referenceNo);

          //  console.log(formData);

          try {
            const response2 = await axios.post(
              `https://events.vit.ac.in/events/THANIMA23/cnfpay/`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            // setIsLoading(true);
            //  console.log(response2);
            // if (response2.status === 303) {
            //    console.log(response2.headers);
            // }
          } catch (error) {
            console.error("CTS payment error", error);
            window.location.href = `https://events.vit.ac.in/events/THANIMA23/startPay/${response.data.data.referenceNo}`;
            if (error?.response?.status === 403) {
              localStorage.removeItem("googleCode");
              localStorage.removeItem("registeredThanima");
              localStorage.removeItem("registerNumber");
              navigate("/");
            } else if (
              error?.response?.data.data.message === "Invalid token."
            ) {
              localStorage.removeItem("googleCode");
              localStorage.removeItem("registeredThanima");
              localStorage.removeItem("registerNumber");
              navigate("/");
            } else if (
              response?.data.data.message ===
              "You do not have permission to perform this action."
            ) {
              localStorage.removeItem("googleCode");
              localStorage.removeItem("registeredThanima");
              localStorage.removeItem("registerNumber");
              navigate("/");
            }
            // console.log(error.response);
            // console.log(error.response.headers);
            // console.log(error.response.headers.Location);
          }
        } catch (error) {
          setIsLoadingPay(false);
          setShowPopup(false);
          toast.error(error.response.data.message);
          console.log(error);
          if (error?.response?.status === 403) {
            localStorage.removeItem("googleCode");
            localStorage.removeItem("registeredThanima");
            localStorage.removeItem("registerNumber");
            navigate("/");
          } else if (error?.response?.data.data.message === "Invalid token.") {
            localStorage.removeItem("googleCode");
            localStorage.removeItem("registeredThanima");
            localStorage.removeItem("registerNumber");
            navigate("/");
          } else if (
            response?.data.data.message ===
            "You do not have permission to perform this action."
          ) {
            localStorage.removeItem("googleCode");
            localStorage.removeItem("registeredThanima");
            localStorage.removeItem("registerNumber");
            navigate("/");
          }
        }
      }
    } catch (error) {
      setIsLoadingPay(false);
      setShowPopup(false);
      console.error("Error fetching uniqueid", error);
      toast.error(error.response.data.message);
      if (error?.response?.status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (error?.response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  const fetchEventsDashboard = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/dashboard/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      // console.log(response);
      setEvents(response.data.data.events);
      setRegistrations(response.data.data.registrations);
      setRegisterNumber(response.data.data.regNo);
      setName(response.data.data.name);
      setPayed(response.data.data.payment_done);
      setIsLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error?.response?.status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (error?.response?.data.data.message === "Invalid token.") {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error?.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchEventsDashboard();
    fetchEvents();
    // console.log(process.env.REACT_APP_BACKEND_URI);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  useEffect(() => {
    if (!authToken || !registrationComplete) {
      navigate("/");
    }
  }, []);

  const category1 = events.filter((event) => event.category === "Pre-Thanima");
  const category2 = events.filter((event) => event.category === "Sports");
  const category3 = events.filter(
    (event) => event.category === "Digital Event"
  );

  if (isLoading) {
    return (
      <div className="loader">
        <ReactLoading
          className="loading-anim"
          type="bubbles"
          color="#FFDE37"
          height={100}
          width={50}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: "40px",
        background:
          "linear-gradient(180deg, #f3d465 0%, #8c1b1d 40.18%, #4f0a0b 100%)",
      }}
    >
      <ToastContainer />
      <div className="dahsboard_container">
        <div className="profile">
          <div className="i">
            <FaUserAlt />
          </div>
          <h4>{name} </h4> <h4>{registerNumber}</h4>
        </div>
        <div
          className="event_detail_container"
          style={{ marginBottom: "20px" }}
        >
          <div className="event_image" style={{ width: "70%" }}>
            <img src="/images/logo2.png" alt="thanima_main" />
          </div>
          <div className="event-text-container">
            <div className="top-text">
              <h2 style={{ color: "#3d0a0b" }}>Thanima 2023</h2>
            </div>
            <div className="details">
              <FaCalendarAlt className="i" />
              <p>1st October 2023</p>
            </div>
            <div className="details">
              <FaMapMarkerAlt className="i" />
              <p>Anna Auditorium </p>
            </div>
            <div className="details">
              <FaMoneyBillAlt className="i" />
              <p>Rs. 600.0/- (inclusive of gst)</p>
            </div>
            {payed ? (
              <div
                className="button"
                style={{ cursor: "select", textAlign: "center" }}
              >
                Payment Done
              </div>
            ) : (
              <button
                type="button"
                className="button"
                disabled={isLoadingPay}
                onClick={() => setShowPopup(true)}
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
        {showPopup && (
          <div className="popup_container">
            <div className="popup" style={{ padding: "20px 40px" }}>
              <FaTimes className="i" onClick={() => setShowPopup(false)} />
              <ul className="popup-list">
                <p>Payment Instructions</p>
                <li>
                  Please make sure you click "Complete Registration" after
                  completing the payment.
                </li>
                <li>
                  If payment was success, it should be reflected in the portal
                  immediately. If not, please wait for 2 hours before trying
                  again.
                </li>
                <li>
                  If amount was debited and even after 2 hours, you cannot see
                  "Payment Done" message, your money will be refunded back in
                  3-4 days. You should pay again to register for the event
                </li>
                <li>
                  In the above mentioned case, please click on
                  <Link
                    style={{
                      color: "#333",
                      textDecoration: "underline",
                      fontFamily: "Lato",
                    }}
                    to="https://forms.gle/6ZpVjnEcnqo7RYUy5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Report Issue{" "}
                  </Link>
                  at the Payment Failed page, or fill the form provided in the
                  bottom of the page.
                </li>
              </ul>

              <p className="popup-text">Proceed to payments?</p>
              <div className="button-section">
                <button
                  type="button"
                  disabled={isLoadingPay}
                  className="accept"
                  onClick={fetchQR}
                >
                  Accept
                  {isLoadingPay && (
                    <>
                      <div class="simple-spinner">
                        <span></span>
                      </div>
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className="reject"
                  onClick={() => setShowPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {events.length > 0 && (
          <>
            <h2>Pre Thanima</h2>
            <div className="event_list">
              {/* category1 events */}
              {category1.map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  registrations={registrations}
                  showDeadline={false}
                />
              ))}
            </div>
            <hr />
            <h2>Sports</h2>
            <div className="event_list">
              {/* category2 events */}
              {category2.map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  registrations={registrations}
                  showDeadline={false}
                />
              ))}
            </div>
            <hr />
            <h2>Digital</h2>
            <div className="event_list">
              {/* category3 events */}
              {category3.map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  registrations={registrations}
                  showDeadline={true}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="queries">
        <p>
          For inquiries and assistance,{" "}
          <a
            className="link"
            rel="noreferrer"
            href="https://forms.gle/EFihZTEc5RQts8Pi7"
            target="_blank"
          >
            contact us
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Dashboard;

// if (response2.status === 200) {
//   navigate("/paymentsuccess");
// } else {
//   navigate("/paymentfailed");
// }

// console.log(events);
// console.log(name);
// console.log(registerNumber);
// const data = {
//   unique_id: response.data,
//   name: response.data.name,
//   reg_no: response.data.reg_no,
//   contact: response.data.contact,
//   amount: 625.0,
// };
// const jsonPayload = JSON.stringify(data);
// const response2 = await axios.post(
//   `${process.env.REACT_APP_BACKEND_URI}`,
//   jsonPayload,
//   }
// );
// if (response2.status === 200) {
//   navigate("/paymentsuccess");
// } else {
//   navigate("/paymentfailed");
// }
// console.log(events);
// console.log(name);
// console.log(registerNumber);
