import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const Navbar2 = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const closeNavbar = () => {
    setIsActive(false);
  };

  const signOutFunction = () => {
    localStorage.removeItem("googleCode");
    localStorage.removeItem("registeredThanima");
    localStorage.removeItem("registerNumber");
    navigate("/");
  };

  return (
    <>
      <nav className="navbar">
        <div className="nav_container">
          <div className="toggle-btn" onClick={toggleNavbar}>
            <FaBars className="i" />
          </div>
          <div className={`nav-items ${isActive ? "active" : ""}`}>
            <ul>
              <li>
                <NavLink to="" style={{ color: "#fff" }} onClick={closeNavbar}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{ color: "#fff" }}
                  to="/dashboard"
                  onClick={closeNavbar}
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{ color: "#fff" }}
                  to="/dashboard/invitation"
                  onClick={closeNavbar}
                >
                  Invitations
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={{ color: "#fff" }}
                  to="/dashboard/myevents"
                  onClick={closeNavbar}
                >
                  My Teams
                </NavLink>
              </li>
              <li style={{ cursor: "pointer" }} onClick={signOutFunction}>
                <p
                  style={{
                    fontWeight: "600",
                    fontFamily: "Marcellus SC",
                    color: "#fff",
                  }}
                >
                  Sign Out
                </p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar2;

// import React, { useState } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import { FaBars } from "react-icons/fa";

// const Navbar2 = () => {
//   const [isActive, setIsActive] = useState(false);
//   const navigate = useNavigate();
//   const toggleNavbar = () => {
//     setIsActive(!isActive);
//   };
//   const signOutFunction = () => {
//     localStorage.removeItem("googleCode");
//     localStorage.removeItem("registeredThanima");
//     navigate("/");
//   };
//   return (
//     <>
//       <nav className="navbar">
//         <div className="nav_container">
//           <div className="toggle-btn" onClick={toggleNavbar}>
//             <FaBars className="i" />
//           </div>
//           <div className={`nav-items ${isActive ? "active" : ""}`}>
//             <ul>
//               <li>
//                 <NavLink to="" style={{ color: "#fff" }}>
//                   Home
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink style={{ color: "#fff" }} to="/dashboard">
//                   Dashboard
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink style={{ color: "#fff" }} to="/dashboard/invitation">
//                   Invitations
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink style={{ color: "#fff" }} to="/dashboard/myevents">
//                   My Events
//                 </NavLink>
//               </li>
//               <li style={{ cursor: "pointer" }} onClick={signOutFunction}>
//                 <p
//                   style={{
//                     fontWeight: "600",
//                     fontFamily: "Marcellus SC",
//                     color: "#fff",
//                   }}
//                 >
//                   Sign Out
//                 </p>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Navbar2;
