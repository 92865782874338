import React from "react";
import { Link, useLocation } from "react-router-dom";

const PaymentFailed = () => {
  const location = useLocation();
  const message = new URLSearchParams(location.search).get("message");

  return (
    <div className="payment_container">
      <div className="payment_box">
        <h1 style={{ color: "#e9ca97", letterSpacing: "2px" }}>
          Payment Failed
        </h1>
        <div className="payment_wrapper">
          <p>{message ? message : "Retry Your Payment Process."}</p>
          <div className="button_wrapper">
            <button type="button" className="button">
              <Link to={`/dashboard`}>Back to Dashboard</Link>
            </button>
            <button type="button" className="button">
              <Link to="https://forms.gle/6ZpVjnEcnqo7RYUy5" target="_blank" rel="noopener noreferrer">Report Issue</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
