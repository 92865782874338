import React from "react";
import { Link, useLocation } from "react-router-dom";

const RegistrationFailed = () => {
  const location = useLocation();
  const message = new URLSearchParams(location.search).get("message");
  return (
    <div className="payment_container">
      <div className="payment_box">
        <h1 style={{ color: "#e9ca97", letterSpacing: "2px" }}>
          Registration Failed
        </h1>
        <div className="payment_wrapper">
          <p className="success_pay">{message}</p>
          <div style={{ display: "flex" }}>
            <button
              style={{ marginInline: "10px" }}
              type="button"
              className="button"
            >
              <Link to={`/`}>Go Back</Link>
            </button>
            <button
              style={{ marginInline: "10px" }}
              type="button"
              className="button"
            >
              <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSc9iPkQrCfLBrNkaV8xQYcdW02V1QnL_PreSrXC4WPkZSs1UQ/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Report Issue
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationFailed;
