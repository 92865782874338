import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

// import { FaLinkedinSquare } from "react-icons/fa"; // Import the LinkedIn icon
import bigLogo from "../background/footerlogo.png"; // Import the logo image
import {
  FaFacebook,
  FaInstagram,
  FaInstagramSquare,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <hr id="footerhr" style={{ background: "#fff" }} />
        <div className="content">
          <img className="footer-logo" src={bigLogo} alt="Thanima Logo" />
          <div className="connect">
            <h1>CONNECT WITH US</h1>
            <center>
              <a href="https://www.instagram.com/thanima_vit">
                <img
                  className="social-logo"
                  id="insta"
                  src="images/insta.svg"
                  alt="insta"
                />
              </a>
              <a href="https://www.youtube.com/user/vitthanima">
                <img className="social-logo" src="images/yt.svg" alt="yt" />
              </a>
              <a href="https://www.facebook.com/vit.thanima/?ref=page_internal">
                <img
                  className="social-logo"
                  id="mail"
                  src="images/fb.png"
                  alt="fb"
                />
              </a>
            </center>
          </div>
          <div className="contact">
            <h1>CONTACT US</h1>
            <h2 style={{ fontSize: "16px", color: "#fff" }}>
              vitthanima@gmail.com
            </h2>
          </div>
        </div>

        <div className="bottom" style={{ padding: "0px 0px 40px" }}>
          <h1 style={{ fontSize: "15px", color: "#fff" }}>
            © Copyright 2023 Thanima Team
          </h1>
          <h2 style={{ fontSize: "14px", color: "#fff" }}>
            Developed by :
            <span id="developers" style={{ fontSize: "14px", color: "#000" }}>
              <a
                style={{ fontSize: "14px", color: "#fff" }}
                href="https://www.linkedin.com/in/alok-naushad/"
              >
                {" "}
                Alok Naushad
              </a>{" "}
              ,{" "}
              <a
                style={{ fontSize: "14px", color: "#fff" }}
                href="https://www.linkedin.com/in/riya-sailesh/"
              >
                Riya Sailesh
              </a>
              ,
              <a
                style={{ fontSize: "14px", color: "#fff" }}
                href="https://www.linkedin.com/in/abhisheksmattam/"
              >
                {" "}
                Abhishek S Mattam
              </a>
            </span>
          </h2>

          <h2 style={{ fontSize: "14px", color: "#fff" }}>
            Designed by :
            <span style={{ fontSize: "14px", color: "#fff" }}>
              <a
                style={{ fontSize: "14px", color: "#fff" }}
                href="https://www.linkedin.com/in/karthik-ajay/"
              >
                {" "}
                Karthik AB
              </a>
              ,{" "}
              <a
                style={{ fontSize: "14px", color: "#fff" }}
                href="https://www.instagram.com/johann_t_koshy/"
              >
                {" "}
                Johann Thomas Koshy
              </a>
            </span>
          </h2>
        </div>
      </footer>
    </>
  );
};

export default Footer;

// {
//   /* <footer id="footer">
// <hr id="footerhr" />
// <div className="content">
//   <img className="footer-logo" src={bigLogo} alt="Thanima Logo" />
//   <div className="connect">
//     <h1>CONNECT WITH US</h1>
//     <center>
//       <a href="https://www.instagram.com/thanima_vit">
//         <img
//           className="social-logo"
//           id="insta"
//           src="images/insta.svg"
//           alt="insta"
//         />
//       </a>
//       <a href="https://www.youtube.com/user/vitthanima">
//         <img className="social-logo" src="images/yt.svg" alt="yt" />
//       </a>
//       <a href="https://www.facebook.com/vit.thanima/?ref=page_internal">
//         <img
//           className="social-logo"
//           id="mail"
//           src="images/fb.png"
//           alt="fb"
//         />
//       </a>
//     </center>
//   </div>
//   <div className="contact">
//     <h1>CONTACT US</h1>
//     <h2>vitthanima@gmail.com</h2>
//   </div>
// </div>

// <div className="bottom">
//   <h1>© Copyright 2023 Thanima Team</h1>
//   <h2>
//     Developed by :
//     <span id="developers">
//       <a href="https://www.linkedin.com/in/alok-naushad/">
//         {" "}
//         Alok Naushad
//       </a>{" "}
//       ,{" "}
//       <a href="https://www.linkedin.com/in/riya-sailesh/">
//         Riya Sailesh
//       </a>
//       ,
//       <a href="https://www.linkedin.com/in/abhisheksmattam/">
//         {" "}
//         Abhishek S Mattam
//       </a>
//     </span>
//   </h2>

//   <h2>
//     Designed by :
//     <span>
//       <a href="https://www.linkedin.com/in/karthik-ajay/">
//         {" "}
//         Karthik AB
//       </a>
//       ,{" "}
//       <a href="https://www.instagram.com/johann_t_koshy/">
//         {" "}
//         Johann Thomas Koshy
//       </a>
//     </span>
//   </h2>
// </div>
// </footer> */
// }
