import React from "react";
import "./PaymentSuccess.css";
import { Link } from "react-router-dom";
const PaymentSuccess = () => {
  return (
    <div className="payment_container">
      <div className="payment_box">
        <h1 style={{ color: "#e9ca97", letterSpacing: "2px" }}>
          Payment Success
        </h1>
        <div className="payment_wrapper">
          <p className="success_pay">Come join us this 1st October!</p>
          <button type="button" className="button">
            <Link to={`/dashboard`}>Back to Dashboard</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
