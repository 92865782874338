import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import EventDetails from "./components/EventDetails/EventDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import { EventProvider } from "./components/EventsContext";
import Landing from "./components/Landing/Landing";
import GoogleCallback from "./components/GoogleCallback";
import Register from "./components/Register/Register";
import Form from "./components/Form/Form";
import Navbar2 from "./components/Navbar2/Navbar2";
import Invitations from "./components/Invitations/Invitations";
import Error from "./components/Error/Error";
import AddTeam from "./components/AddTeam/AddTeam";
import MyEvents from "./components/MyEvents/MyEvents";
import UPI from "./UPI/UPI";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "./components/PaymentFailed/PaymentFailed";
import RegistrationFailed from "./components/RegistrationFailed/RegistrationFailed";

function App() {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const registerationComplete = localStorage.getItem("registeredThanima");

  return (
    <div className="App">
      {/* {isLandingPage && <Navbar />} */}
      {!isLandingPage &&
        location.pathname !== "/googleCallback" &&
        location.pathname !== "/registration" && <Navbar2 />}

      <EventProvider>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          {registerationComplete && (
            <Route exact path="/dashboard" element={<Dashboard />} />
          )}
          <Route exact path="/dashboard/invitation" element={<Invitations />} />
          <Route
            exact
            path="/dashboard/event/addteam/:eventId"
            element={<AddTeam />}
          />
          <Route path="/form" element={<Form />} />
          <Route path="/dashboard/event/:eventId" element={<EventDetails />} />
          <Route path="/googleCallback" element={<GoogleCallback />} />
          <Route path="/dashboard/register/:eventId" element={<Register />} />
          <Route path="/dashboard/myevents" element={<MyEvents />} />
          <Route path="/registration" element={<UPI />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route path="/paymentfailed" element={<PaymentFailed />} />
          <Route path="/registrationfailed" element={<RegistrationFailed />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </EventProvider>
    </div>
  );
}

export default App;

// const initialEvents = [
//   {
//     eventId: "1",
//     name: "Event 1",
//     category: "Category 1",
//     dateTime: "Date & Time 1",
//     venue: "Venue 1",
//     imageUrl: "/images/event1.jpg",
//   },
//   {
//     eventId: "2",
//     name: "Event 2",
//     category: "Category 2",
//     dateTime: "Date & Time 2",
//     venue: "Venue 2",
//     imageUrl: "/images/event2.jpg",
//     description:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, numquam voluptatum? Voluptatum enim et culpa! Quia, vitae nihil corrupti molestias sint quibusdam tempore sapiente non deleniti, fugiat accusamus, porro cumque!",
//   },
//   // Add more events as needed
// ];
// const [events, setEvents] = useState(initialEvents);
