import React, { useEffect } from "react";
import "./Landing.css";
import BackgroundImage from "../background/kalaribg.png";
import Gallery from "../Gallery/Gallery";
import Carousel from "../Carousel/Carousel";
import Footer from "../Footer/Footer2";
import Navbar from "../Navbar/Navbar";
import bgBoat from "../background/boats.png";
import boat from "../background/boat.png";
import bubble from "../background/bubble.png";
import bubble2 from "../background/bubble2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactLoading from "react-loading";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowAltCircleRight, FaArrowRight, FaTimes } from "react-icons/fa";
import axios from "axios";

const Landing = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  // const authToken = localStorage.getItem("googleCode");

  useEffect(() => {
    // Simulate a delay to mimic loading
    const delay = setTimeout(() => {
      setIsPageLoaded(true);
    }, 2000); // Set your desired loading duration in milliseconds

    // Cleanup the timeout to prevent memory leaks
    return () => clearTimeout(delay);
  }, []);
  // const [showPopup, setShowPopup] = useState(false);
  // const [isLoadingPay, setIsLoadingPay] = useState(false);
  // const navigate = useNavigate();
  // const fetchQR = async () => {
  //   try {
  //     setIsLoadingPay(true);

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URI}/api/payments/start/`,
  //       {
  //         headers: {
  //           Authorization: `Token ${authToken}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       try {
  //         let formData = new FormData();
  //         formData.append("registrationNo", response.data.data.referenceNo);
  //         formData.append("name", response.data.data.name);
  //         formData.append("email", response.data.data.email);
  //         formData.append("mobileNo", response.data.data.mobileNo);
  //         formData.append("amount", response.data.data.amount);
  //         //    console.log(response.data.data.referenceNo);

  //         //  console.log(formData);

  //         try {
  //           const response2 = await axios.post(
  //             `https://events.vit.ac.in/events/THANIMA23/cnfpay/`,
  //             formData,
  //             {
  //               headers: {
  //                 "Content-Type": "multipart/form-data",
  //               },
  //             }
  //           );
  //           // setIsLoading(true);
  //           //  console.log(response2);
  //           // if (response2.status === 303) {
  //           //    console.log(response2.headers);
  //           // }
  //         } catch (error) {
  //           console.error("CTS payment error", error);
  //           window.location.href = `https://events.vit.ac.in/events/THANIMA23/startPay/${response.data.data.referenceNo}`;
  //           if (error?.response?.status === 403) {
  //             localStorage.removeItem("googleCode");
  //             localStorage.removeItem("registeredThanima");
  //             localStorage.removeItem("registerNumber");
  //             navigate("/");
  //           } else if (
  //             error?.response?.data.data.message === "Invalid token."
  //           ) {
  //             localStorage.removeItem("googleCode");
  //             localStorage.removeItem("registeredThanima");
  //             localStorage.removeItem("registerNumber");
  //             navigate("/");
  //           } else if (
  //             response?.data.data.message ===
  //             "You do not have permission to perform this action."
  //           ) {
  //             localStorage.removeItem("googleCode");
  //             localStorage.removeItem("registeredThanima");
  //             localStorage.removeItem("registerNumber");
  //             navigate("/");
  //           }
  //           // console.log(error.response);
  //           // console.log(error.response.headers);
  //           // console.log(error.response.headers.Location);
  //         }
  //       } catch (error) {
  //         setIsLoadingPay(false);
  //         setShowPopup(false);
  //         toast.error(error.response.data.message);
  //         console.log(error);
  //         if (error?.response?.status === 403) {
  //           localStorage.removeItem("googleCode");
  //           localStorage.removeItem("registeredThanima");
  //           localStorage.removeItem("registerNumber");
  //           navigate("/");
  //         } else if (error?.response?.data.data.message === "Invalid token.") {
  //           localStorage.removeItem("googleCode");
  //           localStorage.removeItem("registeredThanima");
  //           localStorage.removeItem("registerNumber");
  //           navigate("/");
  //         } else if (
  //           response?.data.data.message ===
  //           "You do not have permission to perform this action."
  //         ) {
  //           localStorage.removeItem("googleCode");
  //           localStorage.removeItem("registeredThanima");
  //           localStorage.removeItem("registerNumber");
  //           navigate("/");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoadingPay(false);
  //     setShowPopup(false);
  //     console.error("Error fetching uniqueid", error);
  //     toast.error(error.response.data.message);
  //     if (error?.response?.status === 403) {
  //       localStorage.removeItem("googleCode");
  //       localStorage.removeItem("registeredThanima");
  //       localStorage.removeItem("registerNumber");
  //       navigate("/");
  //     } else if (error?.response?.data.data.message === "Invalid token.") {
  //       localStorage.removeItem("googleCode");
  //       localStorage.removeItem("registeredThanima");
  //       localStorage.removeItem("registerNumber");
  //       navigate("/");
  //     } else if (
  //       error.response?.data.data.message ===
  //       "You do not have permission to perform this action."
  //     ) {
  //       localStorage.removeItem("googleCode");
  //       localStorage.removeItem("registeredThanima");
  //       localStorage.removeItem("registerNumber");
  //       navigate("/");
  //     }
  //   }
  // };
  const registrationComplete = localStorage.getItem("registeredThanima");
  const authCode = localStorage.getItem("googleCode");

  const handleGoogleSignIn2 = (e) => {
    e.preventDefault();

    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CALLBACK_URI}/googleCallback&scope=email%20profile&response_type=code&hd=vitstudent.ac.in`
    );
  };
  const countries = [
    {
      image: "/images/1.png",
      title: "Vadamvali",
      desc: "Vadamvali, commonly known as Tug of War, is an exhilarating and engaging outdoor team sport that beckons players and spectators alike to join the fun. Picture two spirited teams, brimming with enthusiasm, standing on opposite sides of a marked battlefield – be it a lush field or a muddy arena. Their mission? To summon every ounce of strength, coordination, and strategy to pull a heavy rope in opposing directions, all while trying to inch a central flag or marker over to their side. The heart-pounding thrill of Vadamvali lies not only in the physical challenge but also in the companionship it fosters and the electric atmosphere it creates. Whether you're a seasoned competitor or a curious onlooker, this timeless game promises an irresistible blend of teamwork, excitement, and friendly competition. Step into the world of Vadamvali and experience the joy of pulling together towards victory!",
    },
    {
      image: "/images/2.png",
      title: "Musical Chair",
      desc: "As we celebrate this joyous festival of colors, culture, and togetherness, let's add some excitement to the festivities. Gather around in a circle of chairs adorned with vibrant decorations that capture the essence of this cherished festival. Allow the traditional music to guide your graceful movements as you dance in rhythm around the chairs. When the music stops, it's your moment to secure a seat and continue the enjoyment. Immerse yourself in the thrill of friendly competition and savor the chase as we celebrate the joy of togetherness. ",
    },
    {
      image: "/images/3.png",
      title: "Sack race",
      desc: "Sack racing, an enthralling and captivating outdoor sport, challenges your agility and balance as you indulge yourself in the thrill of a frienly competition. Hop to victory in a sack, aiming to be the first to reach the finish line. Experience an adrenaline filled rush with your fellow competitors as you dash for the victory. Dive into the enchantment of this joyous event and embody the festival's vibrant spirit.",
    },
    {
      image: "/images/2.png",
      title: "Pookalam",
      desc: "Step into the enchanting world of the Pookalam Competition, a centerpiece of Onam celebrations in Kerala, India. Here, vibrant floral artistry comes to life as participants create stunning designs using colorful petals and leaves. It's an invitation to immerse yourself in the rich cultural tapestry of Onam, where creativity knows no bounds. Teams and individuals compete, crafting unique, fragrant masterpieces inspired by tradition and nature. Judges assess craftsmanship and visual appeal, adding friendly competition to the festivities. Join us to experience the magic of tradition, artistry, and community spirit at the Pookalam Making Competition – an invitation to savor the beauty and culture of Onam!",
    },
    {
      image: "/images/1.png",
      title: "Sundarikk pott thodal",
      desc: "Sundarikk Pottu Thodal is a captivating game of luck and artistry where participants, blindfolded and spun around, try to position a bindi (or pottu) precisely on a portrait's center. Channel your inner artist as you aim for the perfect placement. The player who nail the closest placement emerge victorious. Merging both expertise and fortune, this game puts your precision to the test. With your eyes covered and relying on instinct, faith, and a touch of imagination, you'll endavour the art of precision and accuracy. Where laughter knows no bounds, and the excitement takes your breath away join us to find out the whimsical piece of abstract art!  Rally your friends for a fun filled evening of enjoyment and bonding.",
    },
    {
      image: "/images/3.png",
      title: "Badminton",
      desc: "One of the world's top racquet sports, badminton embodies skill, tactics, and zeal. On either side of a court that is divided by a net, the opposing players take up positions.Players face off across a net-divided court, striving to score by launching a shuttlecock into the opponent's half.The rally is over when the shuttlecock touches the court on the opposite team’s side.This swift-moving sport challenges your dexterity and hand-eye coordination as you vie for victory.Gear up for a high-energy tournament for both men and women! Brace yourselves to seize this opportunity to be a part of this exhilarating Onam extravaganza.",
    },
    {
      image: "/images/2.png",
      title: "Thalapanthukalli",
      desc: " It's not just a game; it's an epic battle of skill and laughter that you won't want to miss. You, armed with a coconut leaf ball and a stick, facing off against your rivals in a thrilling game of Thalapanthukali. The mission? To throw the ball over your head (Thala) and hit that stump like a champion! But beware, the opposing team is poised for action, ready to catch the ball or flick the stump to declare you OUT! It's not your ordinary game; it's an adventure of strength, strategy, and coconut-powered precision. The coconut leaf ball is your weapon, and you'll wield it with gusto! It's no featherweight; it's a 300-gram beast that demands your best shots.",
    },
    {
      image: "/images/3.png",
      title: "Football",
      desc: " Calling all college football fanatics and aspiring soccer superstars: it's time to lace up your boots and showcase your skills at the most thrilling event of the Onam season! Get ready for an adrenaline-pumping football event for men.! Gather your friends and college comrades to form your dream team, and prepare to go head-to-head with the fiercest competition on the campus.This is your chance to prove that you've got what it takes to be the football sensation of the year.What's in it for you? The ultimate football champions will not only earn bragging rights but also walk away with fantastic rewards that will make this celebration truly unforgettable.Join us to experience the thrill of intense football battles in a vibrant Onam atmosphere and  build lasting friendships with fellow participants who share your passion for the game. Lace up, gear up, and kick off your journey to football greatness at this event. It's more than a game; it's your chance to shine! ",
    },
  ];
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  if (!isPageLoaded) {
    return (
      <div className="loader">
        <ReactLoading
          className="loading-anim"
          type="bubbles"
          color="#FFDE37"
          height={100}
          width={50}
        />
      </div>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <ToastContainer />

      <Navbar />
      <div id="landing-container">
        <span className="spark"></span>
        <span className="spark2"></span>
        <span className="spark3"></span>
        <span className="spark4"></span>

        {/* <div
          className="boatrace"
          style={{
            position: "absolute",
            backgroundImage: `url(${bgBoatRace})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        ></div> */}
        <section
          id="home"
          style={
            {
              // backgroundImage: `url(${LandingBackground})`,
              // backgroundSize: "cover",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "center center",
            }
          }
        >
          <div
            className="boatrace"
            style={{
              position: "absolute",
              backgroundImage: `url(${boat})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>

          <div
            className="bubble"
            style={{
              position: "absolute",
              backgroundImage: `url(${bubble})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
          <div
            className="bubble2"
            style={{
              position: "absolute",
              backgroundImage: `url(${bubble2})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>

          <div className="logo-icon">
            <img src="/images/thanima_without_bg.png" alt="Thanima Logo" />
          </div>
          {registrationComplete === "true" && authCode && (
            <Link
              to="/dashboard"
              className="btn google-btn"
              style={{
                textAlign: "center",
                color: "#fff",
                width: "fit-content",
                padding: "10px",
              }}
            >
              Go to Dashboard{" "}
              <FaArrowRight style={{ fontSize: "12px", marginLeft: "5px" }} />
            </Link>
          )}

          {authCode && registrationComplete === "false" && (
            <Link
              to="/form"
              className="btn google-btn"
              style={{
                textAlign: "center",
                color: "#fff",
                width: "fit-content",
                padding: "10px",
              }}
            >
              Registration Form{" "}
              <FaArrowRight style={{ fontSize: "12px", marginLeft: "5px" }} />
            </Link>
          )}

          {!authCode && !registrationComplete && (
            <button onClick={handleGoogleSignIn2} className="google-btn">
              <div className="google-icon-wrapper">
                <img
                  className="google-icon"
                  src="/images/devicon_google.png"
                  alt="google"
                />
              </div>
              <p className="btn-text">
                <b>Sign in with Google</b>
              </p>
            </button>
          )}

          {/* {!registrationComplete && !authCode && (
            <button onClick={handleGoogleSignIn2} className="google-btn">
              <div className="google-icon-wrapper">
                <img
                  className="google-icon"
                  src="/images/devicon_google.png"
                  alt="google"
                />
              </div>
              <p className="btn-text">
                <b>Sign in with Google</b>
              </p>
            </button>
          )}
          {registrationComplete && (
            <Link
              to="/dashboard"
              className="btn google-btn"
              style={{
                textAlign: "center",
                color: "#fff",
                width: "fit-content",
                padding: "10px",
              }}
            >
              Go to Dashboard{" "}
              <FaArrowRight style={{ fontSize: "12px", marginLeft: "5px" }} />
            </Link>
          )} */}
          {/* <button onClick={handleGoogleSignIn}>Sign In with Google</button> */}
        </section>
        <section
          id="about"
          // style={
          //   {
          //      backgroundImage: `url(${BackgroundImage})`,
          //   }
          //}
        >
          {/* <button type="button" onClick={() => setShowPopup(true)}>
            Button
          </button> */}

          <div
            className="boats"
            style={{
              position: "absolute",
              backgroundImage: `url(${bgBoat})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
          <h1 data-aos="fade-up">About Thanima</h1>
          <p className="about-content" data-aos="fade-up">
            From the resplendent florals gracing our homes to the delectable
            Onasadhya feast savored with gusto, Onam reigns supreme as the
            Malayalee community's most cherished festival, uniting hearts
            worldwide. Within the vibrant Malayalee community at VIT, the
            celebration of Onam is an unwavering tradition. Enter 'Thanima,' an
            annual extravaganza that first dazzled in 2002.
          </p>
          <br />
          <p className="about-content" data-aos="fade-up">
            Over the years, it has evolved into a grand spectacle, welcoming
            renowned guests such as Vineeth Srinivasan, Swetha Menon, Shri. K.
            B. Ganesh Kumar, Deepak Dev, Shri. G Karthikeyan, Siddhi
            Mahajankatti, Vayalar Ravi (MP), and Marina Michael Kurisingal.
            Thanima is more than an event; it's a kaleidoscope of culture, a
            crucible of competition, and a testament to unity.
          </p>
          <br />
          <p className="about-content" data-aos="fade-up">
            Cultural performances and spirited contests infuse it with energy,
            fostering sportsmanship and camaraderie. This is where values shine,
            where tradition meets modernity, and where our Malayalee family
            stands united. Thanima is an annual burst of joy, an explosion of
            colors, and a testament to the enduring Onam spirit that burns in
            our hearts.
          </p>
        </section>
        <section id="contests">
          <Carousel images={countries} />
        </section>
        <section
          id="gallery"
          style={{ overflow: "hidden", marginBottom: "40px" }}
        >
          <h1 data-aos="fade-up">Gallery</h1>
          <div data-aos="fade-up">
            <Gallery />
          </div>
          <Link
            className="view"
            style={{ color: "#000" }}
            target="_blank"
            to="https://www.facebook.com/vit.thanima/photos/"
          >
            View More
          </Link>
        </section>
        <section id="contact" style={{ maxHeight: "fit-content" }}>
          <Footer />
        </section>
      </div>
    </div>
  );
};

export default Landing;

//OLD WAY

// const handleGoogleSignIn = (e) => {
//   // window.location.href = `${baseURL}/api/auth/google/login`;
//   e.preventDefault();
//   window.location.href =
//     "{BASE}/api/auth/google/login/";
// };
// const scopes = "profile email"; // You can add more scopes as needed, separated by spaces

// useEffect(() => {
//   function start() {
//     gapi.client.init({
//       clientId:
//         "64436018518-7vnvjul700ekgc0tp6bq4271q4vmcnpf.apps.googleusercontent.com",
//       scope: scopes,
//     });
//   }
//   gapi.load("client:auth2", start);
// }, []);
// const onFailure = (res) => {
//   console.log("Login Failure! ", res);
// };

// {/* <GoogleLogin
//     clientId={process.env.CLIENT_ID}
//     onSuccess={(res) => handleGoogleLogin(res)}
//     onFailure={onFailure}
//     // cookiePolicy={"single_host_origin"}
//     // isSignedIn={true}
//   /> */}
// const handleGoogleLogin = (response) => {
//   console.log(response);
//   // axios
//   //   .post(`${baseURL}/auth/convert-token`, {
//   //     token: response.accessToken,
//   //     backend: "google-oauth2",
//   //     grant_type: "convert_token",
//   //     client_id: process.env.CLIENT_ID,
//   //     client_secret: process.env.CLIENT_SECRET,
//   //   })
//   //   .then((res) => {
//   //     const { access_token, refresh_token } = res.data;
//   //     //console.log({ access_token, refresh_token });
//   //     localStorage.setItem("access_token", access_token);
//   //     localStorage.setItem("refresh_token", refresh_token);
//   //   })
//   //   .catch((err) => {
//   //     console.log("Error Google login", err);
//   //   });
// };
