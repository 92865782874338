import React, { useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import EventContext from "../EventsContext";
import "./EventDetails.css";
import {
  FaList,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaCalendarTimes,
  FaClock,
} from "react-icons/fa";

const EventDetails = () => {
  const { eventId } = useParams();
  const { events, registration, fetchEvents, eventsLoaded } =
    useContext(EventContext);
  const event = events.find((e) => parseInt(e.id) == eventId);
  const registered = registration.find((e) => parseInt(e.event) == eventId);

  console.log(registered);

  useEffect(() => {
    if (!eventsLoaded) {
      fetchEvents();
    }
  }, []);
  function formatDateTime(timestamp) {
    if (!timestamp) {
      return { formattedDate: "", formattedTime: "" };
    }

    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      return { formattedDate: "", formattedTime: "" };
    }

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const dayStr =
      day +
      (day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th");
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    const formattedDate = `${dayStr} ${month} ${year}`;

    return { formattedDate, formattedTime };
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  if (!event) {
    return <div>Event not found</div>;
  }
  const HtmlRenderer = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  console.log(event.team_max, event.team_min);
  const memberCount =
    event.team_max === event.team_min
      ? "1"
      : `${event.team_min} - ${event.team_max}`;
  console.log(memberCount);

  return (
    <div className="events_main_container">
      <div className="event_detail_container">
        <div className="event_image">
          <img src={`${event.image}`} alt={event.name} />
        </div>
        <div className="event-text-container">
          <div className="top-text">
            <h2 style={{ width: "fit-content" }}>{event.name}</h2>
            {registered?.team?.complete ? (
              <div>
                <button type="button" className="event_button">
                  <Link to={`/dashboard/myevents`}>View More</Link>
                </button>
              </div>
            ) : (
              <div>
                {!registered ? (
                  <Link
                    className="event_button"
                    to={`/dashboard/register/${event.id}`}
                    style={{
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    }}
                  >
                    Register
                  </Link>
                ) : event.type === "Team" ? (
                  <Link
                    style={{
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    }}
                    className="event_button"
                    to={`/dashboard/event/addteam/${event.id}`}
                  >
                    Add Team
                  </Link>
                ) : registered.file ? (
                  <Link
                    className="event_button"
                    target="_blank"
                    to={`${registered.file}`}
                    style={{
                      color: "#fff",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    }}
                  >
                    View Submission
                  </Link>
                ) : (
                  <button
                    type="button"
                    style={{ color: "#fff", fontFamily: "Poppins" }}
                    className="event_button"
                  >
                    Registered
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="details">
            <FaList className="i" />
            <p> {event.category}</p>
          </div>
          <div className="details">
            <FaClock className="i" />
            <p>{formatDateTime(event.deadline).formattedDate}</p>
          </div>
          {event.category !== "Digital Event" && (
            <div className="details">
              <FaCalendarAlt className="i" />
              <p>{formatDateTime(event.date).formattedDate}</p>
            </div>
          )}

          <div className="details">
            <FaMapMarkerAlt className="i" />
            <p> {event.location}</p>
          </div>
          <div className="other_details">
            <div className="det">
              {event.team_max === 1 ? (
                <p className="det_title">Participation</p>
              ) : (
                <p className="det_title">Team Size</p>
              )}
              <p>
                {event.team_max === 1 ? (
                  <p>Individual</p>
                ) : (
                  <p>
                    {event.team_min !== event.team_max
                      ? `${event.team_min} - ${event.team_max}  members`
                      : `${event.team_min}  members`}
                  </p>
                )}
              </p>
            </div>
            <div className="det">
              <p className="det_title">Eligibility</p>
              <p>{event.eligible_genders.join("/")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="event_desc">
        <div className="part rules">
          <HtmlRenderer htmlString={event.rules} />
        </div>
        <div className="part judging_criteria">
          <HtmlRenderer htmlString={event.judging_criteria} />
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
