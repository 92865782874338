import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Form.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const Form = () => {
  const navigate = useNavigate(); // Get the navigate function
  const authToken = localStorage.getItem("googleCode");
  const registrationComplete = localStorage.getItem("registeredThanima");

  const [formData, setFormData] = useState({
    full_name: "",
    contact: "",
    gender: "",
    reg_no: "",
  });

  const [errors, setErrors] = useState({
    full_name: "",
    contact: "",
    gender: "",
    reg_no: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate full name
    if (formData.full_name.trim() === "") {
      setErrors({ ...errors, full_name: "Full name is empty" });
      return;
    }

    const namePattern = /^[A-Za-z\s]{4,50}$/;
    if (!namePattern.test(formData.full_name)) {
      setErrors({ ...errors, full_name: "Full name is invalid" });
      return;
    }

    // Validate contact number
    if (formData.contact.length < 10) {
      setErrors({ ...errors, contact: "Contact number is invalid" });
      return;
    }

    // Validate reg_no using a regular expression
    const regNoPattern = /^[0-9]{2}[A-Z]{3}[0-9]{4}$/;
    if (!regNoPattern.test(formData.reg_no)) {
      setErrors({ ...errors, reg_no: "Please enter a valid VIT reg no." });
      return;
    }

    // Validate gender
    if (formData.gender === "") {
      setErrors({ ...errors, gender: "Please select a gender" });
      return;
    }

    try {
      const jsonPayload = JSON.stringify(formData);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/auth/register/`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success(response.message);
        navigate("/dashboard");
      } else if (response.status === 403) {
        toast.error(response?.data.data.message);
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (response?.data.data.message === "Invalid token.") {
        toast.error(response?.data.data.message);
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        toast.error(response?.data.data.message);
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else {
        console.error("POST request error:", response.status);
        toast.error(response?.data.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.data.message);
      console.error("POST request error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({ ...errors, [name]: "" });
  };
  useEffect(() => {
    if (!authToken) {
      navigate("/");
      // console.log("After navigate");
    }
    if (registrationComplete == "true") {
      navigate("/dashboard");
    }
  }, []);
  if (authToken && registrationComplete == "true") {
    window.location.replace(`/dashboard`);
  } else {
    return (
      <div className="register-form-container">
        <ToastContainer />
        <h1>Register</h1>
        <form className="register_form" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="input-group">
              <div className="label-group">
                <label className={`label mandatory light`} htmlFor="full_name">
                  Full Name
                  <span>*</span>
                </label>
              </div>
              <input
                placeholder="Enter your full name."
                className="inp-field"
                type="text"
                id="full_name"
                name="full_name"
                value={formData.full_name}
                onChange={handleInputChange}
              />
            </div>
            {errors.full_name && (
              <p className="error-message">{errors.full_name}</p>
            )}
            <div className="input-group">
              <div className="label-group">
                <label className={`label mandatory light`} htmlFor="contact">
                  Phone No.
                  <span>*</span>
                </label>
              </div>
              <input
                name="contact"
                placeholder="Enter your contact number."
                className="inp-field"
                type="text"
                minLength="10"
                id="contact"
                value={formData.contact}
                onChange={handleInputChange}
              />
            </div>
            {errors.contact && (
              <p className="error-message">{errors.contact}</p>
            )}

            <div className="input-group">
              <div className="label-group">
                <label className={`label mandatory light`} htmlFor="reg_no">
                  Registration Number
                  <span>*</span>
                </label>
              </div>
              <input
                name="reg_no"
                placeholder="Enter your registration number."
                className="inp-field"
                type="text"
                id="reg_no"
                value={formData.reg_no}
                onChange={handleInputChange}
              />
            </div>
            {errors.reg_no && <p className="error-message">{errors.reg_no}</p>}

            <div className="input-group">
              <div className="label-group">
                <label className={`label mandatory light`} htmlFor="gender">
                  Gender
                  <span>*</span>
                </label>
              </div>
              <select
                className="inp-field"
                value={formData.gender}
                onChange={handleInputChange}
                name="gender"
              >
                <option value="">Select an option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            {errors.gender && <p className="error-message">{errors.gender}</p>}
          </div>

          <button type="submit" className="button">
            Submit
          </button>
        </form>
      </div>
    );
  }
};
export default Form;

//import axios from "axios";
// import React, { useState } from "react";
// import FormInputField from "../FormInputField/FormInputField";

// const Form = () => {
//   // Get form data
//   const [formData, setFormData] = useState({
//     full_name: "",
//     contact: "",
//     gender: "",
//     reg_no: "",
//   });
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.put(
//         `{BASE}/api/auth/register`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Handle the response, e.g., show success message, redirect, etc.
//       console.log("PUT request success:", response.data);
//     } catch (error) {
//       // Handle errors, e.g., show error message
//       console.error("PUT request error:", error);
//     }
//   };
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const [selectedOption, setSelectedOption] = useState("");
//   return (
//     <div>
//       <h1>Register</h1>
//       <form className="register_form" onSubmit={handleSubmit}>
//         <div className="form-group">
//           <div className="input-group">
//             <div className="label-group">
//               <label className={`label mandatory light`} name="full_name">
//                 Full Name
//                 <span>*</span>
//               </label>
//             </div>
//             <input
//               placeholder="Enter your full name."
//               className="inp-field"
//               type="text"
//               id="full_name"
//               onChange={handleInputChange}
//             />
//           </div>

//           <div className="input-group">
//             <div className="label-group">
//               <label className={`label mandatory light`} htmlFor="reg_no">
//                 Registration Number
//                 <span>*</span>
//               </label>
//             </div>
//             <input
//               name="reg_no"
//               placeholder="Enter your registration number."
//               className="inp-field"
//               type="text"
//               id="reg_no"
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="input-group">
//             <div className="label-group">
//               <label className={`label mandatory light`} htmlFor="contact">
//                 Contact
//                 <span>*</span>
//               </label>
//             </div>
//             <input
//               name="contact"
//               placeholder="Enter your contact number."
//               className="inp-field"
//               type="number"
//               minLength="10"
//               id="contact"
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="input-group">
//             <div className="label-group">
//               <label className={`label  mandatory light`} htmlFor="gender">
//                 Gender
//                 <span>*</span>
//               </label>
//             </div>
//             <select
//               className="inp-field"
//               value={selectedOption}
//               onChange={(e) => setSelectedOption(e.target.value)}
//               name="gender"
//             >
//               <option value="">Select an option</option>
//               <option value="male">Male</option>
//               <option value="female">Female</option>
//             </select>
//           </div>
//         </div>

//         <button type="submit" className="button">
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default Form;
