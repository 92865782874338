import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="payment_container">
      <h1 style={{ fontSize: "28px", letterSpacing: "2px" }}>
        Page Not Found {" :("}
      </h1>
      <button type="button" className="button">
        <Link to={`/`}>Go Back</Link>
      </button>
    </div>
  );
};

export default Error;
