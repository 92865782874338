import React from "react";
import { QRCodeCanvas } from "qrcode.react"; //importing component from QR library
import "./UPI.css";
import { Link } from "react-router-dom";
const UPI = () => {
  return (
    <>
      <nav className="navbar spl">
        <div className="qr_nav_container">
          <div className="qr-items">
            <div className="logo">
              <img src="images/logo.png" alt="Logo" />
            </div>
          </div>
        </div>
      </nav>
      <div className="upi_container">
        <h1>Register</h1>
        <div className="qr_container">
          <p className="scan">Scan to Pay!</p>

          <QRCodeCanvas
            size={300}
            value="upi://pay?pa=arunajay188@okicici&pn=Arun%20Ajay&am=625.00&cu=INR&tn=Pay%20For%20Thanima"
          />
          <p className="info">
            UPI ID :<strong> arunajay188@okicici</strong>
          </p>
          <p className="info">
            Payment Amount :<strong> Rs. 625</strong>
          </p>
        </div>
        <button className="button confirm">
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLScHxqTHu3vudV-xlnIbIL09Js34Dqyh8-BMBW-U0tsuFBoNGA/viewform">
            Submit Confirmation
          </Link>
        </button>
      </div>
    </>
  );
};

export default UPI;
