import axios from "axios";
import React, { useEffect, useState } from "react";
import "./MyEvents.css";
import ReactLoading from "react-loading";
import { FaEye, FaEyeSlash, FaTimes, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MyEvents = () => {
  const authToken = localStorage.getItem("googleCode");
  const [requests, setRequests] = useState([]);
  const [registration, setRegistration] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTeamMembers, setShowTeamMembers] = useState({});
  const navigate = useNavigate();

  const category1 = registration.filter((registration) => registration.team);
  const category2 = registration.filter((registration) => !registration.team);
  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/dashboard/`,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setRequests(response.data.data.requests);
      setRegistration(response.data.data.registrations);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching events:", error);
      if (requests.error) {
        console.log("Req");
      }
      if (error?.response?.status === 403) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      } else if (
        error?.response?.data.data.message ===
        "You do not have permission to perform this action."
      ) {
        localStorage.removeItem("googleCode");
        localStorage.removeItem("registeredThanima");
        localStorage.removeItem("registerNumber");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  function formatDateTime(timestamp) {
    if (!timestamp) {
      return { formattedDate: "", formattedTime: "" };
    }

    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      return { formattedDate: "", formattedTime: "" };
    }

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const dayStr =
      day +
      (day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th");
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    const formattedDate = `${dayStr} ${month} ${year}`;

    return { formattedDate, formattedTime };
  }

  if (isLoading) {
    return (
      <div className="loader">
        <ReactLoading
          className="loading-anim"
          type="bubbles"
          color="#FFDE37"
          height={100}
          width={50}
        />
      </div>
    );
  }

  return (
    <div className="myevents-container">
      <h1>My Teams</h1>
      <div className="myevents-wrapper">
        <div className="invitation-display">
          {registration?.length === 0 ? (
            <div className="null_msg">No Events Registered!</div>
          ) : (
            <>
              {category1?.map((event) => (
                <div className="invite_card" key={event.id}>
                  <h3>{event.team?.event.name}</h3>
                  <p className="detail">
                    <span>Team Name -</span> {event.team?.name}
                  </p>
                  <p className="detail">
                    <span>Team Leader -</span> {event.team?.leader.full_name}
                  </p>
                  <p className="detail">{event.team?.event.location}</p>
                  <div className="date_n_time">
                    <p className="detail">
                      {formatDateTime(event.team?.event.date).formattedTime}
                    </p>
                    |
                    <p className="detail">
                      {formatDateTime(event.team?.event.date).formattedDate}
                    </p>
                  </div>
                  <hr></hr>
                  {event.team?.members && (
                    <>
                      <div
                        className="member_show"
                        style={{
                          color: "#333",
                          cursor: "pointer",
                          padding: "1px 10px ",
                        }}
                        onClick={() =>
                          setShowTeamMembers((prevState) => ({
                            ...prevState,
                            [event.id]: !prevState[event.id],
                          }))
                        }
                      >
                        <h4
                          className="detail spl"
                          style={{
                            fontSize: "14px",
                            color: "#333",
                            paddingRight: "10px",
                            paddingBottom: "5px",
                          }}
                        >
                          Team Members{" "}
                        </h4>
                        {showTeamMembers[event.id] ? (
                          <FaTimes style={{ color: "#333" }} /> // Use a different icon for hiding
                        ) : (
                          <FaEye /> // Use the "eye" icon
                        )}
                        {/* {showTeamMembers[event.id]
                          ? "Team Members"
                          : "Team Members"} */}
                      </div>

                      {showTeamMembers[event.id] && (
                        <div
                          className="members"
                          style={{
                            background: "#fff",
                            margin: "5px",
                            boxShadow: "0px 4px 8px 4px rgba(0,0,0,0.15)",
                          }}
                        >
                          {event.team.members
                            ?.filter(
                              (item) =>
                                item.full_name !== event.team.leader.full_name
                            )
                            .map((item) => (
                              <p className="detail" key={item.id}>
                                {item.full_name}
                              </p>
                            ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyEvents;

//  {/* {requests?.map((event) => (
//     <div className="invite_card" key={event.id}>
//       <h3>{event.event.name}</h3>
//       <p className="detail">
//         <span>Team Name -</span> {event.team.name}
//       </p>

//       <p className="detail">{event.event.location}</p>

//       <div className="date_n_time">
//         <p className="detail">
//           {formatDateTime(event.event.date).formattedTime}
//         </p>
//         -
//         <p className="detail">
//           {formatDateTime(event.event.date).formattedDate}
//         </p>
//       </div>
//     </div>
//   ))} */}
