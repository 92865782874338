import { Link } from "react-router-dom";
import "./EventCard.css";
import { FaClock } from "react-icons/fa";

const EventCard = ({ event, registrations, showDeadline }) => {
  const hasUserRegistered = (eventId) => {
    // Check if the user's registrations include the event ID
    const present = registrations?.some(
      (registration) => registration.event === eventId
    );
    // console.log(present);
    return present;
  };
  function formatDateTime(timestamp) {
    if (!timestamp) {
      return { formattedDate: "", formattedTime: "" };
    }

    const dateObj = new Date(timestamp);
    if (isNaN(dateObj.getTime())) {
      return { formattedDate: "", formattedTime: "" };
    }

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const dayStr =
      day +
      (day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th");
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    const formattedDate = `${dayStr} ${month} ${year}`;

    return { formattedDate, formattedTime };
  }

  return (
    <div className="event_card">
      {hasUserRegistered(event.id) && (
        <span className="tag registered-tag">Registered</span>
      )}
      <div className="event_image">
        <img src={`${event.image}`} alt={event.name} />
      </div>
      <p className="category">{event.category}</p>
      <h3>{event.name}</h3>

      <p className="detail">{event.location}</p>
      <div className="date_n_time">
        <p className="detail">
          {showDeadline
            ? formatDateTime(event.deadline).formattedTime
            : formatDateTime(event.date).formattedTime}
        </p>
        |
        <p className="detail">
          {showDeadline
            ? formatDateTime(event.deadline).formattedDate
            : formatDateTime(event.date).formattedDate}
        </p>
      </div>
      <div
        className="detail"
        style={{
          fontSize: "11.5px",
          fontWeight: "500",
          paddingTop: "5px",
        }}
      >
        Deadline:{" "}
        <span>
          {formatDateTime(event.deadline).formattedTime}
          {", "}
          {formatDateTime(event.deadline).formattedDate}
        </span>
      </div>

      <Link to={`/dashboard/event/${event.id}`} className="btn">
        View More
      </Link>
    </div>
  );
};

export default EventCard;
